import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect } from 'react';

import { UnmountWithTransition } from 'src/shared/components/core/unmount-with-transition/unmount-with-transition';
import { Loader } from 'src/shared/components/loader';
import { WellFormManager } from 'src/store/well-form-manager/well-form-manager';
import { WellFormManagerWithGeoTasksHistory } from 'src/store/well-form-manager/well-form-manager-with-geo-tasks-history';

import { TabsSubheader } from './tabs-subheader/tabs-subheader';
import { TSplitApproachOptions } from './types';
import { WellFormComponent } from './well-form-component';

import styles from './well-form.module.scss';

type Props = {
  wellFormManager: WellFormManager;
  subheader: ReactNode;
  isFormLoading?: boolean;
  isFormFetching?: boolean;
  additionalTabSubheaderComponent?: ReactNode;
  onAddNewApproach?(): void;
  onSplitApproach({ sectionId, stage, approach }: TSplitApproachOptions): void;
};

export const WellForm = observer(function WellForm({
  wellFormManager,
  subheader,
  isFormLoading,
  isFormFetching = false,
  additionalTabSubheaderComponent,
  onSplitApproach,
  onAddNewApproach,
}: Props) {
  const formStore = wellFormManager.currentFormStore;

  useEffect(() => {
    return wellFormManager.resetFormManager;
  }, [wellFormManager]);

  if (wellFormManager.isFormLoading || isFormLoading) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className={styles.formContainer}>
        {subheader}
        {!!formStore && (
          <div className={styles.contentWrapper}>
            <TabsSubheader
              formStore={formStore}
              additionalTabComponent={additionalTabSubheaderComponent}
              addWellToChartStore={wellFormManager.addWellToChartStore}
            />
            <WellFormComponent
              formStore={formStore}
              onAddWellToChart={wellFormManager.addWellToChartStore.openSidebar}
              onSplitApproach={onSplitApproach}
              onAddNewApproach={onAddNewApproach}
            />
          </div>
        )}
      </div>
      <UnmountWithTransition
        className={styles.formFetchingLoader}
        isShown={
          isFormFetching ||
          !!formStore?.isLoading ||
          (wellFormManager instanceof WellFormManagerWithGeoTasksHistory && wellFormManager.isCopying)
        }
        mountStylesObj={{ opacity: 1 }}
        unmountStylesObj={{ opacity: 0 }}
      >
        <Loader size="large" />
      </UnmountWithTransition>
    </>
  );
});
