import { makeObservable, observable, action } from 'mobx';

import { assert } from 'src/shared/utils/assert';

import { INumberRestrictions } from '../../../features/well-form/types';
import { NumberValidation } from '../../../features/well-form/validation/number-validation';
import { Field } from '../abstract-control-entities';

export type TNumberFieldData = {
  formElementRefId: string;
  fieldId: string;
  useInMainProgressBar: boolean;
  numberOfDecimalPlaces?: number;
  objectName?: string;
  placeholder?: string;
  unit?: string;
  restrictions: INumberRestrictions;
  initialValue?: number;
};

export class NumberField extends Field<number | null> {
  @observable initialValue: number | null = null;
  @observable value: number | null;
  readonly numberOfDecimalPlaces?: number;
  readonly validation: NumberValidation;

  constructor(data: TNumberFieldData) {
    super(data);
    this.value = data.initialValue || null;
    this.validation = new NumberValidation(data.restrictions);
    this.numberOfDecimalPlaces = data.numberOfDecimalPlaces;

    if (this.value !== null) this.hasErrors();
    makeObservable(this);
  }

  checkIsReady(): boolean {
    if (!!this.errorText) return false;
    return this.value !== null;
  }

  @action.bound
  clearError(): void {
    this.errorText = undefined;
  }

  @action.bound
  clearItem(): void {
    this.clearError();
    this.value = null;
  }

  @action.bound
  hasErrors = (): boolean => {
    const errorText = this.validation.validate(this.value);
    if (!!errorText) this.setError(errorText);
    return !!errorText;
  };

  @action.bound
  setValue(value: number | null, setValueAsInitial?: boolean): void {
    assert(!Number.isNaN(value), 'values is NaN');

    if (this.value === value) {
      return;
    }

    this.clearError();
    this.value = value;

    if (setValueAsInitial) {
      this.setInitialValue(value);
    }
  }

  @action.bound
  setInitialValue(value: number | null): void {
    this.initialValue = value;
  }

  @action.bound
  returnInitialValue(): void {
    this.value = this.initialValue;
  }

  @action.bound
  tryToSetRawValue(value: unknown, setValueAsInitial?: boolean): boolean {
    if (typeof value === 'string' && !Number.isNaN(Number(value))) {
      this.setValue(Number(value), setValueAsInitial);
      return true;
    }
    if (value === 'null') {
      this.setValue(null, setValueAsInitial);
      return true;
    }
    if ((typeof value === 'number' && !Number.isNaN(value)) || value === null) {
      this.setValue(value, setValueAsInitial);
      return true;
    }
    return false;
  }
}
