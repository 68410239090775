import { action, flow, makeObservable, observable } from 'mobx';

import { RigsChartRemoveConfirmationSidebarViewTypes } from 'src/api/chart/rigs-chart-api';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { RootStore } from 'src/store';
import { AppSettingsStore } from 'src/store/app-settings/app-settings-store';
import { Directories } from 'src/store/directories/directories.store';
import { ViewsStore } from 'src/store/views';

import { RawWell } from '../../types';

import { RigOperation } from './entities/rig-operation';
import { DELETING_OBJECT } from './types';
import { getPadTitle, getRigName, mapRigOperations } from './utils';

export class ConfirmationRemoveSidebarStore {
  readonly directories: Directories;
  readonly appSettings: AppSettingsStore;
  readonly views: ViewsStore;

  @observable private view: RigsChartRemoveConfirmationSidebarViewTypes.RigsChartRemoveConfirmationSidebarView | null =
    null;
  @observable isOpen: boolean = false;
  @observable deletingRigOperationsParentRigName: string | null = null;
  @observable deletingPadName: string | null = null;
  @observable deletingRigOperations: RigOperation[] = [];
  @observable affectedRigOperations: RigOperation[] = [];
  @observable isLoading: boolean = false;
  @observable deletingObject: DELETING_OBJECT | null = null;

  constructor(rootStore: RootStore) {
    this.appSettings = rootStore.appSettings;
    this.directories = rootStore.directories;
    this.views = rootStore.views;

    makeObservable(this);
  }

  @action.bound
  init(): void {
    this.loadView();
  }

  @flow.bound
  private async *loadView() {
    this.isLoading = true;

    try {
      const view = await this.views.confirmationRemoveSidebar.loadView();
      yield;
      this.view = view;
    } catch (e) {
      yield;
      console.error(e);
    } finally {
      this.isLoading = true;
    }
  }

  @action.bound
  onOpen(deletingObject: DELETING_OBJECT, deletingRigOperations: RawWell[], affectedRigOperations: RawWell[]): void {
    assert(hasValue(this.view), 'Invalid remove confirmation sidebar view.');

    this.affectedRigOperations = mapRigOperations(this.appSettings, this.directories, this.view, affectedRigOperations);
    this.deletingRigOperations = mapRigOperations(this.appSettings, this.directories, this.view, deletingRigOperations);
    this.deletingObject = deletingObject;
    this.deletingPadName = deletingRigOperations.length
      ? getPadTitle(deletingRigOperations[0], this.directories, this.view)
      : null;

    this.deletingRigOperationsParentRigName = deletingRigOperations.length
      ? getRigName(deletingRigOperations[0], this.directories, this.view)
      : null;
    this.isOpen = true;
  }

  @action.bound
  onClose(): void {
    this.isOpen = false;
    this.deletingRigOperations = [];
    this.affectedRigOperations = [];
    this.deletingObject = null;
  }
}
