import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckmarkIcon } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from 'src/shared/assets/icons/cross.svg';
import { ReactComponent as CopyVersionImg } from 'src/shared/assets/img/copy-version-img.svg';
import { Button } from 'src/shared/components/button';
import { Modal } from 'src/shared/components/modal/modal';
import { PlanVersionStore } from 'src/store/plan-version';

import styles from './copy-version-modal.module.scss';

type Props = {
  planVersionStore: PlanVersionStore;
};

export const CopyVersionModal = observer(function CopyVersionModal({ planVersionStore }: Props) {
  const { t } = useTranslation();
  const {
    isConfirmCopyVersionModalOpened,
    copyingVersion,
    isCopyVersionInProgress,
    confirmCopyVersion,
    cancelCopyVersion,
  } = planVersionStore;

  if (!copyingVersion) {
    return null;
  }

  return (
    <Modal className={styles.modal} isOpened={isConfirmCopyVersionModalOpened} closeOnClickOutside={false}>
      <div className={styles.wrapper}>
        <CopyVersionImg />
        <h2 className={styles.title}>{t('modals:CopyVersion.title')}</h2>
        <p className={styles.descriptionText}>
          {t('modals:CopyVersion.description', { name: copyingVersion.data.name })}
        </p>
        <div className={styles.buttonsWrapper}>
          <Button
            className={styles.button}
            disabled={isCopyVersionInProgress}
            variant="danger"
            prefixIcon={<CrossIcon />}
            onClick={cancelCopyVersion}
          >
            {t('common:Buttons.cancel')}
          </Button>
          <Button
            className={styles.button}
            disabled={isCopyVersionInProgress}
            isLoading={isCopyVersionInProgress}
            variant="success"
            prefixIcon={<CheckmarkIcon />}
            onClick={confirmCopyVersion}
          >
            {t('common:Buttons.accept')}
          </Button>
        </div>
      </div>
    </Modal>
  );
});
