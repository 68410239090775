import { makeObservable, flow, action, observable, computed } from 'mobx';

import { validateFormRequest } from 'src/api/new-well/requests';
import { Validation400ApiError, ValidationError } from 'src/errors';
import { createPromiseController, TPromiseController } from 'src/shared/utils/promise-controller';
import { RootStore } from 'src/store';

import { Item, ValidatableItem } from '../../../shared/entities/abstract-control-entities';
import { FormStore } from '../entities/form.entity';

type TExtraFieldValidation = {
  id: number;
  validate: (item: Item) => void;
};

type TExtraFormValidation = {
  id: number;
  validate: (form: FormStore) => void;
};

export class ValidationManager {
  readonly form: FormStore;
  validationRequest: TPromiseController<boolean> | null = null;
  private readonly rootStore: RootStore;
  private extraFieldValidations: TExtraFieldValidation[] = [];
  private extraFormValidation: TExtraFormValidation[] = [];

  @observable isServerValidationIsProgress = false;

  constructor(form: FormStore, rootStore: RootStore) {
    this.form = form;
    this.rootStore = rootStore;
    makeObservable(this);
  }

  addExtraFieldValidation(validation: TExtraFieldValidation) {
    this.extraFieldValidations.push(validation);
  }

  removeExtraFieldValidation(validation: TExtraFieldValidation) {
    this.extraFieldValidations.remove(validation);
  }

  addExtraFormValidation(validation: TExtraFormValidation) {
    this.extraFormValidation.push(validation);
  }

  removeExtraFormValidation(validation: TExtraFormValidation) {
    this.extraFormValidation.remove(validation);
  }

  @action.bound
  setIsServerValidationInProgress(is: boolean) {
    this.isServerValidationIsProgress = is;
  }

  @flow.bound
  private async *validateControlByServer(item: Item) {
    if (!this.form.tabs || !this.rootStore.drafts.draftVersionId || !item.validationTags?.length) {
      return;
    }
    this.isServerValidationIsProgress = true;
    try {
      await validateFormRequest(this.form.tabs, this.rootStore.drafts.draftVersionId, item.validationTags);
      yield;

      this.setIsServerValidationInProgress(false);
      return true;
    } catch (e) {
      yield;
      item.returnInitialValue();
      this.setIsServerValidationInProgress(false);
      if (e instanceof Validation400ApiError && e.message) {
        this.rootStore.notifications.showErrorMessage(e.message);
      }
      return false;
    }
  }

  async validateControl(item: Item): Promise<void> {
    try {
      this.validationRequest = createPromiseController<boolean>();
      if (item instanceof ValidatableItem) {
        this.extraFieldValidations.forEach((validation) => validation.validate(item));
        const clientValidationResult = item.hasErrors();
        if (clientValidationResult) {
          this.validationRequest.resolve(false);
          return;
        }
      }
      if (item.validateByServer) {
        const serverValidationResult = await this.validateControlByServer(item);
        if (!serverValidationResult) {
          this.validationRequest.resolve(false);
          return;
        }
      }
      this.validationRequest?.resolve(true);
    } finally {
      setTimeout(() => {
        this.validationRequest = null;
      }, 1000);
    }
  }

  checkAreApproachesValid() {
    const approachesList = this.form.approachesTab?.approachesList;
    if (approachesList) {
      if (!approachesList.approaches.length && approachesList.isRequired) {
        throw new ValidationError(this.rootStore.i18.t('newWellForm:Errors.requiredApproach'));
      }
      if (approachesList.approaches.some((approach) => approach.stagesList.isRequired)) {
        if (!approachesList.approaches.some((approach) => approach.stagesList.stages.length)) {
          throw new ValidationError(this.rootStore.i18.t('newWellForm:Errors.requiredStage'));
        }
      }
      if (
        approachesList.approaches.some((approach) =>
          approach.stagesList.stages.some((stage) => stage.sectionsList.required)
        )
      ) {
        if (
          !approachesList.approaches.some((approach) =>
            approach.stagesList.stages.some((stage) => stage.sectionsList.sections.length)
          )
        ) {
          throw new ValidationError(this.rootStore.i18.t('newWellForm:Errors.requiredSection'));
        }
      }

      if (approachesList.approaches.length > 1) {
        for (const appr of approachesList.approaches) {
          const index = approachesList.approaches.indexOf(appr) + 1;
          if (!appr.stagesList.stages.length) {
            throw new ValidationError(this.rootStore.i18.t('newWellForm:Errors.fillApproach', { index }));
          }
        }
      }
    }
  }

  @action.bound
  validateForm(): void {
    let isFormValid = true;

    const validateField = (item: Item): void => {
      this.extraFieldValidations.forEach((validation) => validation.validate(item));
      if (!item.isDisabled) {
        if (item instanceof ValidatableItem && !item.isVisuallyDisabled) {
          const hasError = item.hasErrors();

          if (hasError) {
            isFormValid = false;
          }
        }
      }
    };
    this.checkAreApproachesValid();
    this.extraFormValidation.forEach((validation) => validation.validate(this.form));

    this.form.processFormFields(validateField, { excludeDisabledTabs: true });
    if (!isFormValid) {
      throw new ValidationError(this.rootStore.i18.t('errors:invalidForm'));
    }
  }

  @computed
  get isAllNecessaryControlsAreFilled(): boolean {
    let requiredFieldsCount = 0;
    let filledFieldsCount = 0;

    const checkIsNecessaryFieldFilled = (item: Item): void => {
      if (!item.isDisabled) {
        if (item instanceof ValidatableItem) {
          if (item.restrictions.required) {
            requiredFieldsCount++;
            if (item.isReady) {
              filledFieldsCount++;
            }
          }
        }
      }
    };

    this.form.processFormFields(checkIsNecessaryFieldFilled, { excludeDisabledTabs: true });

    return requiredFieldsCount === filledFieldsCount;
  }

  @computed
  get isAllNecessaryMainFieldsAreFilled(): boolean {
    let requiredFieldsCount = 0;
    let filledFieldsCount = 0;

    const checkIsNecessaryFieldFilled = (item: Item): void => {
      if (!item.isDisabled && !item.isVisuallyDisabled) {
        if (item instanceof ValidatableItem) {
          if (item.restrictions.required) {
            requiredFieldsCount++;
            if (item.isReady) filledFieldsCount++;
          }
        }
      }
    };

    this.form.processFormFields(checkIsNecessaryFieldFilled, {
      excludeApproachesTab: true,
      excludeDisabledTabs: true,
    });

    return requiredFieldsCount === filledFieldsCount;
  }
}
