import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';
import { PlanVersionObjectType } from 'src/api/version/types';

export async function createDraft(planVersionId: number, name: string): Promise<number> {
  try {
    const { data } = await agent.post<PlanVersionObjectType>(`versions/${planVersionId}/tempDraft?name=${name}`);

    return data.id;
  } catch (e) {
    throwApiError(e);
  }
}

export async function deleteCarpetVersion(planVersionId: number): Promise<void> {
  try {
    await agent.delete(`versions/${planVersionId}`);
  } catch (e) {
    throwApiError(e);
  }
}

export async function publishVersion(planVersionId: number): Promise<void> {
  try {
    await agent.post(`versions/${planVersionId}/publish`);
  } catch (e) {
    throwApiError(e);
  }
}

export async function saveVersion(sourcePlanVersionId: number, targetPlanVersionId: number): Promise<void> {
  try {
    await agent.post(`versions/${sourcePlanVersionId}/save`);
  } catch (e) {
    throwApiError(e);
  }
}

export async function createPrivateDraft(planVersionId: number): Promise<number> {
  try {
    const { data } = await agent.post('versions/privateDraft');

    return data.id;
  } catch (e) {
    throwApiError(e);
  }
}

export async function toggleVersionForAnalyticsRequest(planVersionId: number, isChosen: boolean): Promise<void> {
  try {
    await agent.post(
      `versions/${planVersionId}/dashboardLite`,
      {},
      {
        params: {
          availableForDashboardLite: isChosen,
        },
      }
    );
  } catch (e) {
    throwApiError(e);
  }
}

export async function copyCarpetVersion(parentVersionId: number): Promise<void> {
  try {
    await agent.post(`versions/${parentVersionId}/duplicate`);
  } catch (e) {
    throwApiError(e);
  }
}
