import { action, autorun, makeObservable, observable } from 'mobx';

import { assert } from 'src/shared/utils/assert';
import { RootStore } from 'src/store';

import { Item } from '../../../shared/entities/abstract-control-entities';
import { FormStore } from '../entities/form.entity';

import { FormPlugin } from './abstract-form-plugin.entity';

export class ReadOnlyPlugin extends FormPlugin<FormStore> {
  @observable isReadOnlyMode = false;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this);
  }

  connect(form: FormStore): VoidFunction {
    this.form = form;

    return autorun(() => {
      this.setFormReadonlyMode(this.isReadOnlyMode);
    });
  }

  @action.bound
  setIsFormInReadOnlyMode(is: boolean) {
    this.isReadOnlyMode = is;
  }

  @action.bound
  setFormReadonlyMode(isReadonly: boolean) {
    try {
      assert(this.form, 'form instance is not presented');
      const setControlVisualVisibility = (control: Item) => {
        if (control.fieldId !== 'drillingStatus') {
          control.setIsVisuallyDisabled({ flagId: this.pluginId, value: isReadonly });
        }
      };

      this.form.processFormFields(setControlVisualVisibility);

      this.form.approachesTab?.setIsVisuallyDisabled(isReadonly);
      this.form.approaches.forEach((approach) => {
        approach.setIsVisuallyDisabled(isReadonly);
      });
    } catch (e) {
      console.error(e);
      this.rootStore?.notifications.showErrorMessageT('errors:readOnlyFormModeError');
    }
  }
}
